// -- Link resolution rules
// Manages links to internal Prismic documents
// Modify as your project grows to handle any new routes you've made
const linkResolver = doc => {
  if (doc.type === 'how_to_build_page') {
    return `/how-to-build/${doc.uid}/`;
  }

  if (doc.type === 'how_to_build_index') {
    return '/how-to-build/';
  }

  if (doc.type === 'how_to_build_index') {
    return '/how-to-build/';
  }

  if (doc.type === 'how_to_develop_page') {
    return `/how-to-develop/${doc.uid}/`;
  }

  if (doc.type === 'how_to_develop_index') {
    return '/how-to-develop/';
  }

  if (doc.type === 'showcase_post') {
    return `/customers/${doc.uid}/`;
  }

  if (doc.type === 'blog_index') {
    return '/balanced/';
  }

  if (doc.type === 'blog_post') {
    const category = doc.data && doc.data.category ? doc.data.category : '';
    if (category === 'Balanced') {
      return `/balanced/${doc.uid}/`;
    } else if (category === 'Developer Blog') {
      return `/developer-blog/${doc.uid}/`;
    }
  }

  if (doc.type === 'academy_landing') {
    if (doc.uid === 'home') {
      return `/academy/`;
    } else {
      return `/academy/${doc.uid}/`;
    }
  }

  if (doc.type === 'academy_post') {
    return `/academy/${doc.uid.split('__').join('/')}/`;
  }

  if (doc.type === 'category') {
    return `/academy/${doc.uid}/`;
  }

  if (doc.type === 'person') {
    return `/author/${doc.uid}/`;
  }

  if (doc.type === 'podcast_episode') {
    return `/twosided/${doc.uid}/`;
  }

  if (doc.type === 'feature_highlight_page') {
    return `/features/${doc.uid}/`;
  }

  if (doc.type === 'marketplace_type_page') {
    return `/${doc.uid}/`;
  }

  if (doc.type === 'legal_page') {
    return `/${doc.uid}/`;
  }

  if (doc.type === 'landing_page') {
    return `/${doc.uid.split('__').join('/')}/`;
  }

  if (doc.type === 'homepage') {
    return `/`;
  }

  if (doc.type === 'pricing') {
    return `/pricing/`;
  }

  if (doc.type === 'features_folder') {
    return `/features/`;
  }

  return '/';
};

// Note that this file is interpreted as a commonJS module
// If you use "export default linkResolver" instead of module.exports, it will throw an error
module.exports = linkResolver;
